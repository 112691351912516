import ComponentTitle from "../ComponentTitle";
import TrackingLine from "./TrackingLine";
import EducationValue from "./EducationValue";
import {useEffect, useState} from "react";
import Aos from "aos";
import {LandingPageService} from "../../../pages/Services/LandingPageService";

const Education = ({educationList}) => {
    // const {contextService} = LandingPageService();
    // const {fetchEducationType} = contextService;
    // const [educationList, setEducationList] = useState();
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        Aos.init({
            duration: 1000
        })
    })
    
    // useEffect(() => {
    //     fetchEducationType((data) => {
    //         setEducationList(data)
    //     }, () => {
    //         setIsLoading(false)
    //     })
    // }, [])
    
    
    return(
        <section data-aos="fade-down" id={"education"} className={'flex flex-col items-center justify-center w-full bg-slate-100 py-8 px-1 lg:px-8 md:px-8 sm:px-4'}>
            <ComponentTitle title={'Education'} />
            <div className={'flex flex-col lg:flex-row md:flex-row w-5/6 justify-center'}>
                <div className={'py-3 flex flex-col w-full lg:w-1/2 md:w-1/2'}>
                    <h2 className={'text-[#343D68] text-xl font-bold mb-3'}>Formal</h2>
                    {educationList?.formals.map((data, index) => (
                        <div className={'flex gap-8 w-full'} key={index}>
                            <TrackingLine/>
                            <EducationValue education={data} />
                        </div>
                    ))}
                </div>
                <div className={'py-3 flex flex-col w-full lg:w-1/2 md:w-1/2'}>
                    <h2 className={'text-[#343D68] text-xl font-bold mb-3'}>Non Formal</h2>
                    {educationList?.nonFormals.map((data) => (
                        <div className={'flex gap-8 w-full'} key={data.id}>
                            <TrackingLine/>
                            <EducationValue education={data} />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}
export default Education;