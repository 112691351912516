import ComponentTitle from "../ComponentTitle";
import ExperienceCard from "./ExperienceCard";
import {useEffect, useState} from "react";
import Aos from "aos";
import 'aos/dist/aos.css';
import {LandingPageService} from "../../../pages/Services/LandingPageService";

const Experience = ({experiences}) => {
    
    useEffect(() => {
        Aos.init({
            duration: 1000
        });
    }, [])
    
    return(
        <section data-aos="fade-up" id={"experience"} className={'flex flex-col items-center transition duration-700 ease-in-out justify-center py-8 px-1 lg:px-8 md:px-8 sm:px-4'}>
            <ComponentTitle title={'Experience'} />
            {experiences?.map((experience) => (
                <ExperienceCard experience={experience} />
            ))}
        </section>
    )
}
export default Experience;