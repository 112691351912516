import ComponentTitle from "../ComponentTitle";
import PortfolioCard from "./PortfolioCard";
import {Link} from "react-router-dom";

const Portfolio = ({portfolios}) => {
    
    const portfolioReturn = () => {
        const results = [];
        for (let i = 0; i < 3; i++) {
            results.push(<PortfolioCard 
                id={portfolios[i].id}
                description={portfolios[i].description}
                title={portfolios[i].title}
                tags={portfolios[i].tags}
                imageBackground={portfolios[i].imgBackground}
            />)
        }
        return results
        // console.log(portfolios)
    }
    
    return(
        <section id={"portfolio"} className={'py-3 flex flex-col justify-center w-full items-center'}>
            <ComponentTitle title={'Portfolio'} />
            <div className={'flex flex-col lg:flex-row gap-5 items-center justify-center'}>
                {portfolios?.map((data) => (
                    <PortfolioCard
                        id={data.id}
                        description={data.description}
                        title={data.title}
                        tags={data.portfolioTechs}
                        imageBackground={data.photoUrl}
                    />
                ))}
                {/*{portfolioReturn()}*/}
                {/*<PortfolioCard />*/}
                {/*<PortfolioCard />*/}
                {/*<PortfolioCard />*/}
            </div>
            <div className={'mt-16 text-blue-700 hover:text-blue-950'}>
                <Link onClick={() => window.scrollTo(0,0)} to={'/portfolios'}>Show More ...</Link>
            </div>
        </section>
    )
}
export default Portfolio;